<template>
  <div>
    <div class="book">
      <div
        v-for="(pages, index) in pagePrepaidCards"
        :key="pages+index"
        class="page">
        <div class="subpage">
          <div
            style="display: flex; flex-wrap: wrap; margin-left: 5.5%;"
            class="mt-3">
            <div
              v-for="(page, index) in pages"
              :key="page+index"
              class="card-container"
              style="margin: 2px;"
            >
              <div
                style="width: 100%; height: 30%; display: flex; background: #fff;"
                class="pt-3">
                <div
                  v-if="showInfo.logo"
                  class="ml-4 mr-2"
                  style="width: 10%;">
                  <img
                    src="../../assets/stock_icon.png"
                    width="100%">
                </div>
                <div
                  :class="['font-weight-bold mr-3', showInfo.logo ? 'ml-0' : 'ml-4']"
                  style="width: 80%; color: #3A6A75; font-size: 10px;">
                  <span style="white-space: nowrap;">{{ mainTitle ? mainTitle : '' }}</span>
                  <span style="display: block; font-size: 12px;">{{ subTitle }}</span>
                </div>
              </div>
              <div
                class="px-4"
                style="width: 100%; height: 45%; background-color: #fff; display: flex;">
                <div style="display: flex; align-items: center; width: 100%;">
                  <div
                    style="font-size: 10px; width: 65%; line-height: 18px;">
                    <span>{{ $t('prepaidCard.code') }}: {{ page.code }}</span>
                    <span style="display: block;">{{ $t('prepaidCard.name') }}: {{ page.last_name + '_' + page.first_name }}</span>
                    <span>{{ $t('prepaidCard.username') }}:</span>
                    <span
                      class="mt-2"
                      style="display: block">_____________________________________________</span>
                  </div>
                  <div style="width: 35%; display: flex; justify-content: flex-end;">
                    <qrcode-vue
                      :value="page.code"
                      :size="60"
                      level="L"
                      render-as="svg"
                      style="
                        border: 1px solid #000;
                        padding: 5px;
                        justify-content: flex-end;
                        display: flex;
                      "
                    />
                  </div>
                </div>
              </div>
              <div
                class="px-4"
                style="background: #fff; width: 100%; height: 25%; font-size: 10px; color: white; color: black;">
                <span><v-icon
                  size="12"
                  class="mr-1">mdi-phone</v-icon>{{ $t('prepaidCard.support') }}: <span class="font-weight-bold">{{ hotline ? hotline : '' }}</span></span>
                <span style="display: block;">{{ $t('prepaidCard.note') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="btn-print"
      style="position: fixed; bottom: 0;">
      <v-btn
        class="btn"
        color="warning"
        dark
        @click="onPrintPage"
      >
        <v-icon class="mr-2">mdi-printer</v-icon>
        <span style="text-transform: none;">{{
          $t('common.print')
        }}</span>
      </v-btn>
    </div>
    <loading-bar :is-loading="isLoading" />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    QrcodeVue
  },
  data: () => ({
    pagePrepaidCards: [],
    hotline: null,
    mainTitle: null,
    subTitle: null,
    showInfo: {
      logo: false
    },
    isLoading: false
  }),
  computed: {
    ...mapGetters(['PREPAID_CARDS_PRINT_DATA'])
  },
  watch: {
    PREPAID_CARDS_PRINT_DATA () {
      let data = this.PREPAID_CARDS_PRINT_DATA
      this.pagePrepaidCards = []
      let count = 0
      for (let i = 0, size = data.length; i < size; i++) {
        if (Array.isArray(this.pagePrepaidCards[count])) {
          if (this.pagePrepaidCards[count].length < 10) {
            this.pagePrepaidCards[count].push(data[i])
          } else {
            count += 1
            this.pagePrepaidCards[count] = []
            this.pagePrepaidCards[count].push(data[i])
          }
        } else {
          this.pagePrepaidCards[count] = []
          this.pagePrepaidCards[count].push(data[i])
        }
      }
    }
  },
  beforeCreate: function () {
    document.body.className = 'print'
  },
  created () {
    let prepaidCardIds = this.$route.query.prepaidCardIds
    if (prepaidCardIds !== undefined) {
      let prepaidCardInfo = JSON.parse(this.$route.query.prepaidCardInfo)
      this.showInfo.logo = prepaidCardInfo.showInfo?.logo
      this.hotline = prepaidCardInfo.hotline
      this.mainTitle = prepaidCardInfo.mainTitle
      this.subTitle = prepaidCardInfo.subTitle
      let filter = {
        params: {
          prepaidCardIds: prepaidCardIds
        }
      }
      this.GET_PREPAID_CARDS_PRINT(filter)
    }
    let printAllPrepaidCard = this.$route.query.printAllPrepaidCard
    if (printAllPrepaidCard !== undefined) {
      this.isLoading = true
      let prepaidCardInfo = JSON.parse(this.$route.query.prepaidCardInfo)
      this.showInfo.logo = prepaidCardInfo.showInfo?.logo
      this.hotline = prepaidCardInfo.hotline
      this.mainTitle = prepaidCardInfo.mainTitle
      this.subTitle = prepaidCardInfo.subTitle
      let startDate = this.$route.query.startDate
      let endDate = this.$route.query.endDate
      let status = this.$route.query.status
      let rangeQueryStart = prepaidCardInfo.rangeQueryStart
      let rangeQueryEnd = prepaidCardInfo.rangeQueryEnd
      let filter = {
        params: {
          printAllPrepaidCard: printAllPrepaidCard,
          start_date: startDate,
          end_date: endDate,
          status_value: status,
          start_query: rangeQueryStart,
          end_query: rangeQueryEnd
        }
      }
      this.GET_PREPAID_CARDS_PRINT(filter)
        .then(
          function () {
            this.isLoading = false
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    }
  },
  methods: {
    /**
     * Print page
     */
    onPrintPage: function () {
      window.print()
    },
    ...mapActions(['GET_PREPAID_CARDS_PRINT'])
  }
}
</script>

<style lang="scss">
body.print {
    overflow: initial;
}
 body.print {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: #FAFAFA;
        font: 12pt;
    }
    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    .page {
        width: 210mm;
        min-height: 297mm;
        padding: 2mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        // background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    .subpage {
        height: 257mm;
    }

    @page {
        size: A4;
        margin: 0;
    }
    @media print {
        html {
            width: 100%;
            height: 297mm;
        }
        body.print {
            width: 100%;
            height: 297mm;
        }
        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
        .btn-print {
          display: none;
        }
    }
.theme--dark.v-sheet {
    padding: 5px;
}
.card-container {
  width: 90mm;
  height: 55mm;
  border: 1px solid #e5e5e5;
}
</style>
